import React, { ReactNode } from "react";

// Define types for the state and props (if any)
interface ErrorBoundaryState {
	hasError: boolean;
}
interface ErrorBoundaryProps {
	children?: ReactNode;
}
// Here, we assume there are no special props needed, but you could define them if necessary

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error): ErrorBoundaryState {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		// You can log the error to an error reporting service
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// window.location.href = "/error";
			return <></>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
