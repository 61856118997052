import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Element, Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import * as image from "../data/constants/images";
import * as e from "../data/enums";
import { LOGO } from "../data/constants/images";

const headlines = [
	"Secure local LLMs",
	"Bring AI in-house",
	"Local AI solutions",
	"Secure, local AI",
	"Unlock AI's power securely",
	"Instant company knowledge",
	"All knowledge, zero risk",
	"Secure AI deployment",
];
const subHeadlines = [
	"Unlock the power of AI without data risks",
	"Securely empower your team with all your company insights",
	"Reap AI benefits without exposing sensitive data",
	"Transforming company knowledge access",
	"Your data stays in-house",
	"at your fingertips, powered by secure local AI",
	"Maximize Productivity with in-house AI",
	"Empower employees with instant access to company insights",
];

export default function Home(): JSX.Element {
	const { t } = useTranslation("Home");

	const [currentHeadlineIndex, setCurrentHeadlineIndex] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);

	const animationDurationInSeconds = 10;

	useEffect(() => {
		const interval = setInterval(() => {
			setIsAnimating(true);

			setTimeout(() => {
				setCurrentHeadlineIndex((prevIndex) => (prevIndex + 1) % headlines.length);
				setIsAnimating(false);
			}, animationDurationInSeconds & 1000);
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Element name="home">
			<main className="home">
				<section className="hero">
					{/* <img className="nodes" src="img/nodes.png" alt="logo" /> */}
					<div className={`heroTextAndLogo ${isAnimating ? "slide-out" : "slide-in"}`}>
						<h1 key={currentHeadlineIndex}>{headlines[currentHeadlineIndex]}</h1>
						<h2 key={`${currentHeadlineIndex}_sub`}>{subHeadlines[currentHeadlineIndex]}</h2>
					</div>
					<h3>
						Give your team instant access to all organizational knowledge without risking sensitive data
						exposure.
					</h3>
					<div className="cta-container">
						<ScrollLink smooth={true} duration={500} to="contact" className="cta">
							<button>{t("cta")}</button>
						</ScrollLink>
					</div>
				</section>
			</main>
		</Element>
	);
}
