import React from "react";
import * as c from "../../data/constants/constants";

export default function TermsOfService(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Terms of Service for {c.name}</h1>
					<TermsOfServiceContent />
				</div>
			</section>
		</main>
	);
}

export function TermsOfServiceContent(): JSX.Element {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 17 March 2024
				</p>
			</div>

			<article>
				<h2>1. Introduction</h2>
				<p>
					Welcome to {c.name}. Our services, available via a web and mobile app, offer ear training
					exercises with both free and paid plan options. By accessing and using our services, you agree to
					these Terms of Service. Please read them carefully.
				</p>
			</article>

			<article>
				<h2>2. Registration</h2>
				<p>
					To access certain features, you must register for an account. While we do not verify the identity of
					our users, you agree to provide accurate, current, and complete information during the registration
					process. You are also responsible for maintaining the confidentiality of your account details.
				</p>
			</article>

			<article>
				<h2>3. Privacy Policy</h2>
				<p>
					Your use of {c.name} is also governed by our Privacy Policy, which can be found{" "}
					<a href={"mailto:" + c.email}>here</a>.
				</p>
			</article>

			<article>
				<h2>4. Payments, Cancellations, and Service Interruption</h2>
				<p>
					a. Pricing: Information regarding the pricing of our services is available on our website. All fees
					are charged on a subscription basis and are non-refundable except as required by law or as
					specifically stated in these Terms.
				</p>
				<p>
					b. Payment Processor: Payments are processed through a third-party payment processor, Stripe. When
					you use our premium features, you agree to Stripe's terms and conditions, which govern the
					processing of all payments.
				</p>
				<p>
					c. Subscription Management: You can manage your subscription and billing preferences at any time by
					accessing your account settings. Changes to your subscription will take effect at the end of the
					current billing cycle.
				</p>
				<p>
					d. Service Interruption: We strive to provide a consistent and uninterrupted service, but
					interruptions can occur. We are not liable for any loss resulting from service interruptions. We
					will evaluate potential refunds for substantial interruptions on a case-by-case basis.
				</p>
				<p>
					e. Cancellation by User: You may cancel your paid subscription at any time via your account settings
					or by contacting customer support. Cancellations will take effect at the end of the current billing
					cycle, and you will not be charged for the next cycle.
				</p>
				<p>
					f. Refunds: We do not provide refunds for subscription periods you have not used if you decide to
					cancel a subscription before the end of your billing cycle, except in certain circumstances as
					required by law or as covered by a specific promotional guarantee.
				</p>
				<p>
					g. Discontinuation of Service: If we discontinue a service, we will provide you with reasonable
					advance notice and an opportunity to retrieve data from your account before the service is
					discontinued. We will not refund any fees upon the discontinuation of a service.
				</p>
			</article>

			<article>
				<h2>5. Intellectual Property Rights and License Grant</h2>
				<p>
					{c.name} grants you a limited, non-exclusive, non-transferable, and revocable license to
					access and use our services for your personal and non-commercial ear training purposes. This license
					does not include any resale or commercial use of any {c.name} service, or its contents;
					any collection and use of any product listings, descriptions, or prices; any derivative use of any
					{c.name} service or its contents; any downloading, copying, or other use of account
					information for the benefit of any third party; or any use of data mining, robots, or similar data
					gathering and extraction tools.
				</p>
				<p>
					All rights not expressly granted to you in these Terms of Service are reserved and retained by {c.name} or its licensors, suppliers, publishers, rights holders, or other content providers. No
					{c.name} service, nor any part of any {c.name} service, may be reproduced,
					duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without
					express written consent of {c.name}. You may not frame or utilize framing techniques to
					enclose any trademark, logo, or other proprietary information (including images, text, page layout,
					or form) of {c.name} without express written consent. You may not use any meta tags or any
					other "hidden text" utilizing {c.name}'s name or trademarks without the express written
					consent of {c.name}. You may use the services only as permitted by law. The licenses
					granted by {c.name} terminate if you do not comply with these Terms of Service.
				</p>
			</article>

			<article>
				<h2>6. User Data and Conduct</h2>
				<p>
					a. User Data: When you participate in ear training exercises on {c.name}, your performance
					may be processed and displayed in your user profile or on a public leaderboard, where applicable.
					This data reflects your usage of the service and is subject to data protection regulations as
					outlined in our Privacy Policy.
				</p>
				<p>
					b. Data Accuracy and Usage: You are responsible for ensuring that any personal data you provide to
					us is accurate, complete, and updated as necessary for the purposes for which it is used.
				</p>
				<p>
					c. Prohibited Conduct: You agree not to use the service for any illegal or unauthorized purpose. You
					also agree to not interfere with or disrupt the service or servers or networks connected to the
					service, including by transmitting any worms, viruses, spyware, malware, or any other code of a
					destructive or disruptive nature.
				</p>
				<p>
					d. Removal of User Data: {c.name} reserves the right to remove any user data or personal
					information from our service at any time if we find that it violates our Privacy Policy, these Terms
					of Service, or if removal is necessary to comply with the law or to protect the rights, property, or
					personal safety of {c.name}, its users, or the public.
				</p>
			</article>

			<article>
				<h2>7. Advertisements and Google AdSense</h2>
				<p>
					Our website may use Google AdSense to serve advertisements. These ads may be tailored to your interests
					based on your browsing history and are governed by Google's Privacy and Terms. We do not control the
					content of the advertisements or the conduct of the third parties that provide the advertisements.
				</p>
				<p>
					You agree that we are not responsible for any loss or damage of any sort incurred as the result of
					any such dealings or as the result of the presence of such advertisers.
				</p>
			</article>

			<article>
				<h2>8. Termination</h2>
				<p>
					{c.name} reserves the right to terminate or suspend your account at any time, with or
					without notice, for conduct that we believe violates these Terms of Service or for any other reason
					we deem necessary.
				</p>
			</article>

			<article>
				<h2>9. Disclaimers</h2>
				<p>
					The services are provided "as is" and "as available" without any warranties of any kind, either
					express or implied.
				</p>
			</article>

			<article>
				<h2>10. Mailing List Subscription</h2>
				<p>
					By creating an account with {c.name}, you agree to be automatically subscribed to our
					mailing list, which is maintained using Brevo. This subscription enables us to send you updates,
					news, promotional offers, and information about our products and services directly to your email
					inbox.
				</p>
				<p>
					Your privacy is important to us, and we commit to handling your email address and any other personal
					information in accordance with our Privacy Policy. You will have the opportunity to opt out of
					receiving these communications at any time through the unsubscribe link provided in every email or
					by managing your subscription preferences in your account settings.
				</p>
				<p>
					Please note that opting out of the mailing list may affect the way you receive important updates and
					offers related to {c.name}, but will not affect your access to our services.
				</p>
			</article>

			<article>
				<h2>11. Limitation of Liability</h2>
				<p>
					To the fullest extent permitted by law, {c.name} shall not be liable for any indirect,
					incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether
					incurred directly or indirectly.
				</p>
			</article>

			<article>
				<h2>12. Governing Law</h2>
				<p>
					These Terms of Service and any disputes arising out of or related to the services will be governed
					by and construed in accordance with the local laws of the European Union, without giving effect to
					any choice or conflict of law provision or rule.
				</p>
			</article>

			<article>
				<h2>13. Dispute Resolution</h2>
				<p>
					In the event of a dispute arising from or relating to these Terms of Service or your use of the
					services, we strongly encourage you to contact us first at{" "}
					<a href={"mailto:" + c.email}>{c.email}</a> to seek
					a resolution.
				</p>
				<p>
					If we are unable to resolve our dispute amicably, then you and {c.name} agree to submit
					the dispute to binding arbitration. The arbitration shall be conducted in the jurisdiction where
					{c.name} is established, under the rules of the International Chamber of Commerce (ICC).
					The parties agree that the decision rendered by the arbitrator shall be final and binding and may be
					entered as a judgment in any court of competent jurisdiction.
				</p>
				<p>
					Notwithstanding the above, {c.name} may seek and obtain injunctive relief in any
					jurisdiction in any court of competent jurisdiction. With the exception of injunctive relief, both
					you and {c.name} waive the right to initiate or participate in any class action lawsuit or
					class-wide arbitration.
				</p>
				<p>
					This arbitration agreement does not preclude you bringing issues to the attention of federal, state,
					or local agencies. Such agencies can, if the law allows, seek relief against us on your behalf.
				</p>
				<p>
					You agree that any arbitration or proceeding shall be limited to the dispute between {c.name} and you individually. To the full extent permitted by law, (1) no arbitration or
					proceeding shall be joined with any other; (2) there is no right or authority for any dispute to be
					arbitrated or resolved on a class-action basis or to utilize class action procedures; and (3) there
					is no right or authority for any dispute to be brought in a purported representative capacity on
					behalf of the general public or any other persons.
				</p>
				<p>
					You agree that these Terms of Service and any disputes arising out of or related to the services
					will be governed by and construed in accordance with the laws of the European Union, without giving
					effect to any choice or conflict of law provision or rule.
				</p>
			</article>

			<article>
				<h2>14. Changes to Terms</h2>
				<p>
					{c.name} may update these Terms of Service from time to time to reflect changes in our
					practices, service offerings, laws, or regulatory requirements. We will not make any individual
					notification of changes to the Terms of Service to users directly. It is your responsibility to
					check the updated terms periodically at our Terms Of Service page. The "Last Revised" date at the top of the Terms of Service will indicate when the latest changes
					were made. By continuing to use the services after these changes are made, you agree to the revised
					terms.
				</p>
			</article>

			<article>
				<h2>15. Contact</h2>
				<p>
					For any questions about these terms, please contact us at{" "}
					<a href={"mailto:" + c.email}>{c.email}</a> with
					the subject line "TOS Inquiry".
				</p>
			</article>
		</div>
	);
}
