import React from "react";

export default function InfoCard({ imgSrc, title, text }: { imgSrc?: any; title: string; text: any }) {
	return (
		<div className="card infoCard">
			<div className="card-text">
				<h2>{title}</h2>
				<p>{text}</p>
			</div>
			{imgSrc && <img src={imgSrc} alt={title} />}
		</div>
	);
}
