import React from "react";
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";
import * as c from "../data/constants/constants";

export default function Footer(): JSX.Element {
	const { t } = useTranslation("Footer");

	const isMobile = useDeviceIsMobile();
	const currentYear = new Date().getFullYear();
	return (
		<footer>
			<nav>
				{/* <Menu /> */}
				{/* <RouterLink to="/legal">{t("footer.legal")}</RouterLink> */}
				<ScrollLink to="contact" smooth={true} duration={500}>
					{t("footer.contact")}
				</ScrollLink>
			</nav>
			<div className="rights">
				&copy; {currentYear} {c.name} {isMobile && <br />}
				{t("footer.patent")}
			</div>
		</footer>
	);
}

export function Menu() {
	return (
		<div className="menu">
			<ScrollLink smooth={true} duration={500} to="home">
				Home
			</ScrollLink>
			<ScrollLink smooth={true} duration={500} to="benefits">
				Benefits
			</ScrollLink>
			<ScrollLink smooth={true} duration={500} to="security">
				Security
			</ScrollLink>
			<ScrollLink smooth={true} duration={500} to="products">
				Products
			</ScrollLink>
			<ScrollLink smooth={true} duration={500} to="faq">
				FAQ
			</ScrollLink>
			{/* <ScrollLink smooth={true} duration={500} to="clients">
				Who we help
			</ScrollLink> */}
			<ScrollLink smooth={true} duration={500} to="contact">
				Contact
			</ScrollLink>
		</div>
	);
}
