import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

export default function FAQ(): JSX.Element {
	const { t } = useTranslation("FAQ");
	const [openQuestion, setOpenQuestion] = useState<number | null>(null);

	const toggleQuestion = (index: number) => {
		setOpenQuestion(openQuestion === index ? null : index);
	};

	const questions = [1, 2, 3, 4, 5];

	return (
		<Element name="faq">
			<main>
				<section className="features">
					<h2 className="sectionHeader">{t("header")}</h2>
					<div className="faqCard">
						{questions.map((q, index) => (
							<div key={q} className="QA">
								<h3 onClick={() => toggleQuestion(index)}>
									{t(`${q}.q`)}
									<span className={`arrow ${openQuestion === index ? "up" : "down"}`}></span>
								</h3>
								<p className={openQuestion === index ? "visible" : ""}>{t(`${q}.a`)}</p>
							</div>
						))}
					</div>
				</section>
			</main>
		</Element>
	);
}
