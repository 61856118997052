import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as c from "../data/constants/constants";
import * as img from "../data/constants/images";
import * as e from "../data/enums";
import emailjs from "emailjs-com";

export default function Testimonials(): JSX.Element {
	const { t } = useTranslation("Contact");

	return (
		<main className="contact">
			<h1 className="sectionHeader">Contact</h1>
			<div className="contactContainer">
				<div className="info">
					<img className="bgImg" src={img.BG[e.BG.CONTACT]} />
					<h2 className="sectionHeader">
						Put AI to work. <br />
						At work.
					</h2>
					<p>info@prometheonai.com</p>
				</div>
				<div className="contactForm">
					<ContactForm />
				</div>
			</div>
		</main>
	);
}

function ContactForm() {
	const { t } = useTranslation("Contact");

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
		const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
		const userID = process.env.REACT_APP_EMAILJS_USER_ID!;
		console.log(formData);

		emailjs.send(serviceID, templateID, formData, userID).then(
			(response) => {
				console.log(response.status, response.text);
				alert("Message sent successfully!");
				setFormData({ name: "", email: "", message: "" });
			},
			(error) => {
				console.log("FAILED...", error);
				alert("Message failed to send.");
			}
		);
	};

	return (
		<form className="contact-form" onSubmit={handleSubmit}>
			<div>
				<label htmlFor="name">{t("form.name")}</label>
				<input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
			</div>
			<div>
				<label htmlFor="email">{t("form.email")}</label>
				<input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
			</div>
			<div>
				<label htmlFor="message">{t("form.message")}</label>
				<textarea
					id="message"
					name="message"
					value={formData.message}
					onChange={handleChange}
					required
				></textarea>
			</div>
			<button type="submit">{t("form.send")}</button>
		</form>
	);
}
