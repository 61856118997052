import React from "react";
import { useTranslation } from "react-i18next";
import * as img from "../data/constants/images";
import * as e from "../data/enums";
import InfoCard from "../components/InfoCard";

export default function Products(): JSX.Element {
	const { t } = useTranslation("Products");

	return (
		<main>
			<section className="features products">
				<h2 className="sectionHeader">{t("sectionHeader")}</h2>
				<div className="productCards">
					{/* <p className="intro">
						We develop personal assistants that deeply integrate with your company's documents, databases,
						and workflows. By combining advanced natural language processing and retrieval-augmented
						generation technologies, we provide highly contextual, real-time insights.
					</p> */}
					<div className="logoCardContainer">
						<img className="logo" src="img/theon.png" alt={t("theon.title")} />
						<div className="card">
							{/* <h2>{t("theon.title")}</h2> */}
							<p>{t("theon.text.p1")}</p>
							{/* <p>{t("theon.text.p2")}</p> */}
						</div>
					</div>
					<div className="logoCardContainer">
						<img className="logo" src="img/theonPlus.png" alt={t("theon.title")} />
						<div className="card">
							{/* <h2>{t("theonPlus.title")}</h2> */}
							<h3>{t("theonPlus.subtitle")}</h3>
							<p>{t("theonPlus.text.p1")}</p>
							<p>{t("theonPlus.text.p2")}</p>
							<p>{t("theonPlus.text.p3")}</p>
						</div>
					</div>
					<div className="logoCardContainer">
						<img className="logo" src="img/rachel.png" alt={t("theon.title")} />
						<div className="card">
							{/* <h2>{t("rachel.title")}</h2> */}
							<h3>{t("rachel.subtitle")}</h3>
							<p>{t("rachel.text.p1")}</p>
							<p>{t("rachel.text.p2")}</p>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
